.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.sc-1w3tvxe-0 .ejciur {
  display: none !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.soundWaves {
  background-color: white;
  width: calc(100% - 0px);
  /* position: fixed; */
  /* bottom: 28px; */
}

.App-link {
  color: #61dafb;
}

.scrollable-timeline {
  width: calc(100vw - 248px - 48px - 146px);
}

.scrollable {
  width: calc(100vw - 248px - 48px);
}

.scrollable-mobile {
  width: 100vw;
}

.scrollable-blank-state {
  width: 100%;
}

.scrollable-item {
  width: 100%;
}

.react-horizontal-scrolling-menu--item {
  padding-left: 16px !important;
  padding-bottom: 8px !important;
  padding-right: 2px !important;
  padding-top: 8px !important;
}

@media screen and (min-width: 480px) {
  .react-horizontal-scrolling-menu--item {
    padding-left: 16px !important;
    padding-bottom: 8px !important;
    padding-right: 2px !important;
    padding-top: 8px !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#form-file-upload {
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
